// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: "https://apiglamapp.glambox.com.br/",
  production: false,
  firebase: {
    apiKey: "AIzaSyADyiL4mMWKp0zqVbX_lZy-oYChSpwoNzM",
    authDomain: "b4astage-cb80a.firebaseapp.com",
    projectId: "b4astage-cb80a",
    storageBucket: "b4astage-cb80a.appspot.com",
    messagingSenderId: "925318835135",
    appId: "1:925318835135:web:f578064576b444f12dcf0a",
    measurementId: "",
  },
  firebaseRegion: 'southamerica-east1',
  elasticSearch: {
    url: 'https://b4agroup-nqgvmrn-arc.searchbase.io',
    credential:
      'YzgyY2YyNWNkNGQ1Ojc1ZTA1YzIzLWUyZDctNGEyMS1hOWJkLTNkNzdmNjliZDc2OQ=='
  },
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  instaToken:
    'IGQVJWcmNONVRJd0xIQmZAIeFZAkMEVZAcGpzVDBJbTdwZAWxDakt1Y2NCSWVLWFZANYzhnUnZA2SEdiSEVuNnhCU05ySEZAUb2lWcG9BN1FZAVnZAfNmViUWNjUXA5Y0RuMDh5OEFRLXhfTmpPTXl6Qy1LSklZANwZDZD',
  instaApi: 'https://graph.instagram.com/me/media?access_token=',
};

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
