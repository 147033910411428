<footer>
    <div class="toolbar">
        <div class="wrapper">
            <aside class="aside">
                <img
                    class="aside__logo1"
                    src="assets/img/logo-white.svg"
                    alt="mens"
                >
                <div class="aside__content">
                    <ng-container *ngFor="let item of linksMens; let i = index">
                        <div *ngIf="i < linksMens.length - 1">
                            <ng-container *ngIf="item.title !== 'Redes sociais'">
                                <h6>{{ item.title }}</h6>

                                <ng-container *ngFor="let link of item.links">
                                    <a
                                        [title]="link.title"
                                        [href]="link.url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {{ link.title }}
                                    </a>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="i === linksMens.length - 2">
                            <ng-container *ngIf="i === linksMens.length - 2">
                                <h6 class="social-title">{{ linksMens[i + 1].title }}</h6>
                                <div class="social-content">
                                    <a
                                        *ngFor="let link of linksMens[i + 1].links"
                                        [title]="link.title"
                                        [href]="link.url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="social-content__link"
                                    >
                                        <img
                                            [src]="link.icon"
                                            [alt]="link.title"
                                            class="social-content__link__icon"
                                        >
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

            </aside>
            <aside class="aside">
                <img
                    class="aside__logo2"
                    src="assets/img/logo-powered.svg"
                    alt="B4A"
                >
                <div class="aside__content">
                    <div *ngFor="let item of linksB4a">
                        <h6>{{ item.title }}</h6>

                        <ng-container *ngFor="let link of item.links">
                            <a
                                [title]="link.title"
                                [href]="link.url"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {{ link.title }}
                            </a>
                        </ng-container>
                    </div>
                </div>

            </aside>

            <div class="vertical-box">
                <img
                    class="vertical-box__logo"
                    src="assets/img/logo-white.svg"
                    alt="mens"
                >
                <div>
                    <div
                        *ngFor="let item of linksMens"
                        class="item"
                        [ngClass]="item.isOpened ? 'active' : ''"
                    >
                        <div
                            (click)="toggleLinkItem(item)"
                            class="item-header"
                        >
                            <h6>{{ item.title }}</h6>
                        </div>

                        <div class="item-body">
                            <ng-container *ngIf="item.title !== 'Redes sociais'; else socialBlock">
                                <a
                                    *ngFor="let link of item.links"
                                    [title]="link.title"
                                    [href]="link.url"
                                    target="_blank"
                                >
                                    {{ link.title }}
                                </a>
                            </ng-container>

                            <ng-template #socialBlock>
                                <div class="social-content">
                                    <a
                                        *ngFor="let link of item.links"
                                        [title]="link.title"
                                        [href]="link.url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="social-content__link"
                                    >
                                        <img
                                            [src]="link.icon"
                                            [alt]="link.title"
                                            class="social-content__link__icon"
                                        >
                                    </a>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="vertical-box">
                <img
                    class="vertical-box__logo"
                    src="assets/img/logo-powered.svg"
                    alt="B4A"
                >
                <div>
                    <div
                        *ngFor="let item of linksB4a"
                        class="item"
                        [ngClass]="item.isOpened ? 'active' : ''"
                    >
                        <div
                            (click)="toggleLinkItem(item)"
                            class="item-header"
                        >
                            <h6>{{ item.title }}</h6>
                        </div>

                        <div class="item-body">
                            <a
                                *ngFor="let link of item.links"
                                [title]="link.title"
                                [href]="link.url"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {{ link.title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="toolbar__address-info">
            B4A Serviços de Tecnologia e Comércio S.A. Rua Pequetita, 111 - Vila
      Olímpia, São Paulo - SP / Brasil
        </span>
    </div>
</footer>
