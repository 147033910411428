<div id="our-products">
    <div class="wrapper">
        <h2 class="wrapper__title">NOSSOS PRODUTOS TÊM</h2>
    
        <div class="content">
          <div class="circle">
            <img
              class="circle__image"
              src="assets/img/about_product_1.webp"
              alt="Alta Qualidade"
            />
            <strong class="circle__title"> Qualidade</strong>
            <p class="circle__description">
              Experimentamos mais de 10 mil produtos no nosso site Men&apos;s
            Market para chegar a fórmulas de alta performance.
            </p>
          </div>
    
          <div class="circle">
            <img
              class="circle__image"
              src="assets/img/about_product_2.webp"
              alt="Vegano e cruelty free"
            />
            <strong class="circle__title"> Tecnologia </strong>
            <p class="circle__description">
              Desenvolvemos fórmulas com ingredientes de alta tecnologia como o
            M-8VIT, que possui ação multifuncional.
            </p>
            <p></p>
          </div>
    
          <div class="circle">
            <img
              class="circle__image"
              src="assets/img/about_product_3.webp"
              alt="Rotina descomplicada"
            />
            <strong class="circle__title"> Elegância </strong>
            <p class="circle__description">
              Nossa linha tem design exclusivo nas embalagens, trazendo
            minimalismo e modernidade na sua rotina de cuidados.
            </p>
          </div>
        </div>
    
        <a
          href="https://mensmarket.com.br/collections/marca-mens"
          target="_blank"
          class="wrapper__button"
        >
          <button class="btn-primary">SAIBA MAIS</button>
        </a>
      </div>
</div>
