<div id="product-card">
    <div class="card">
      <img [src]="product.images[0]" [alt]="product.name" />
      <a
        target="_blank"
        rel="noopener noreferrer"
      >
        <button class="btn-primary">Comprar</button>
      </a>

      <div class="card__body">
        <div class="rating-content">
          <i
            *ngFor="let item of [1, 2, 3, 4, 5]; let i = index"
            class="rating-content__star"
            [ngClass]="
              getStarRating(product, i) === 'fill'
                ? 'rating-content__star--fill'
                : 'rating-content__star--outline'
            "
          ></i>
        </div>
        <div class="info">
          <span class="info__name">{{ product.name }}</span>
        </div>
      </div>
    </div>
  </div>